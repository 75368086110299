import React, { Component } from 'react';
import { connect } from 'react-redux';

import Form from './Form';

import * as actions from '../actions/item';
import { createUpdate, createAddItemTaxAmount, createUpdateItemTaxAmount, createDeleteItemTaxAmount } from '../actions';
import { closePopup } from '../actions/popup';

import { getTaxOptions } from '../selectors/dropdowns';
import { getFullItem } from '../selectors';

import { BASE_ZINDEX } from '../popup-factory';
import { createFormSelect } from './Form';
import TaxSelect from './TaxSelect';
import { isAvalaraOrder } from '../helpers/order';
import AvalaraCategorySelect from './avalara/AvalaraCategorySelect';

const FormTaxSelect = createFormSelect(TaxSelect);

class EditServicePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      field_lock: 'unit_cost'
    };
  }

  render() {
    const {
      order,
      item,
      default_tax_id,
      onUpdateItem,
      onAddTaxAmount,
      onUpdateTaxAmount,
      onDeleteTaxAmount,
      onClosePopup,
      index,
      zip2tax = false
    } = this.props;

    const { field_lock } = this.state;

    const handleDeleteTaxAmount = tax_amount_id => e => {
      e.preventDefault();
      onDeleteTaxAmount(tax_amount_id);
    };

    const onChangeTax = tax_amount_id => tax_id => {
      onUpdateTaxAmount(tax_amount_id, tax_id);
    };

    const item_name = item.item_name !== '' ? item.item_name : 'Other Service';
    const disabled_title = ['Shipping', 'Freight', 'Fulfillment'].includes(item_name);

    return (
      <div id="service-modal" className ="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index, overflow: 'visible' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Service: {item_name}</h3>
          </div>
        </div>
        <div className ="row popup-content">
          <Form className ="small-12 columns popup-size_limit">
            <Form.TextInput label="Title" field="item_name" value={item_name} disabled={disabled_title} onBlur={onUpdateItem(item.item_id, 'item_name', item.item_name, field_lock)}/>
            <Form.Textarea label="Description" field="item_description" value={item.item_description} onBlur={onUpdateItem(item.item_id, 'item_description', item.item_description, field_lock)}/>
            <Form.TextInput label="Quantity" field="total_units" value={item.total_units} onBlur={onUpdateItem(item.item_id, 'total_units', item.total_units, field_lock)}/>
            <Form.TextInput label="Unit Cost" field="unit_cost" value={item.unit_cost} onBlur={onUpdateItem(item.item_id, 'unit_cost', item.unit_cost, field_lock)}/>
            <Form.TextInput label="Margin" field="total_margin" value={item.total_margin ?? 'N/A'} onBlur={onUpdateItem(item.item_id, 'total_margin', item.total_margin, field_lock)} disabled={item.hidden == 1} />
            <Form.TextInput label="Retail Price" field="unit_price" value={item.unit_price} onBlur={onUpdateItem(item.item_id, 'unit_price', item.unit_price, field_lock)} disabled={item.hidden == 1} />
            <Form.TextInput label="Total" field="total_total" value={item.total_total} disabled={true} />
            {!isAvalaraOrder(order) && item.hidden == 0 && <div className="row field">
              <div className="small-12 medium-4 columns">
                <strong>Taxes</strong>
              </div>
              {item.tax_amounts.map((t, idx) => [
                <FormTaxSelect
                  key={`taxes.${idx}.tax_id`} zip2tax={zip2tax} value={t.tax_id} displayLabel={false}
                  containerClassName={'small-12 medium-4 columns' + (idx !== 0 ? ' medium-offset-4' : '')}
                  field={`taxes.${idx}.tax_id`} required={true} onChange={onChangeTax(t.tax_amount_id)}
                />,
                <Form.TextInput key={`taxes.${idx}.amount`} value={t.amount} displayLabel={false} containerClassName="small-12 medium-3 columns" field={`taxes.${idx}.amount`} disabled={true} />,
                !zip2tax && <div key={`taxes.${idx}.delete`} className="small-12 medium-1 columns" onClick={handleDeleteTaxAmount(t.tax_amount_id)}><a style={{fontSize: '1.5em'}}>&times;</a></div>]
              )}
            </div>}
            {isAvalaraOrder(order) && <div className="row field">
              <div className="small-12 medium-4 columns">
                <strong>Avalara Category</strong>
              </div>
              <div className="small-12 medium-8 columns">
                <AvalaraCategorySelect
                  value={item.avalara_category_id}
                  onChange={(value) => {
                    onUpdateItem(item.item_id, 'avalara_category_id', item.avalara_category_id)(value);
                  }}
                />
              </div>
            </div>}
            {!isAvalaraOrder(order) && item.hidden == 0 && (!zip2tax || item.tax_amounts.length === 0) && <div className="small-12 medium-offset-4 medium-8 columns" style={{marginBottom: '30px'}}>
              <a onClick={e => { e.preventDefault(); onAddTaxAmount(item.item_id, default_tax_id);}}>+ add another tax</a>
            </div>}
          </Form>
        </div>
        <a className ="button" style={{position: 'absolute', right: '1rem', top: '1rem'}}  onClick={e => {e.preventDefault(); onClosePopup();}}>
          Done
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getFullItem(state, ownProps);
  const order = state.entities.orders[item.order_id];
  const job = state.entities.projects[order.job_id];
  return {
    order,
    item,
    default_tax_id: order.tax_id,
    zip2tax: 1 == job.zip2tax,
    taxes: getTaxOptions(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  return {
    onUpdateItem: (item_id, field, previous_value, field_lock) => value => {
      dispatch(updateItem(item_id, field, previous_value, value, { field_lock }));
    },
    onAddTaxAmount: (parent_id, tax_id) => {
      dispatch(createAddItemTaxAmount(parent_id, tax_id));
    },
    onUpdateTaxAmount: (tax_amount_id, tax_id) => {
      dispatch(createUpdateItemTaxAmount(tax_amount_id, tax_id));
    },
    onDeleteTaxAmount: (tax_amount_id) => {
      dispatch(createDeleteItemTaxAmount(tax_amount_id));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedEditServicePopup = connect(mapStateToProps, mapDispatchToProps)(EditServicePopup);
export default ConnectedEditServicePopup;
